//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports

// Material ui Imports
import { Card, CardContent, CardMedia, CircularProgress, Grid, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router";

// Custom components imports (and hooks and helpers)
import { SerializedError, current } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import {
    VTButton,
    VTCard,
    VTTypography,
    useGetMuiBreakPointLabel,
    useOnWindowResize,
} from "@virtus-tech-repository/virtus-tech-repository";

import VTSkeletonCard from "../components/VTSkeletonCard";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { blankScenarioOverview } from "../../helpers/scenarioDataGenerator";
import NoScenarios from "../../assets/images/NoScenarios.svg";
import { useDispatch } from "react-redux";
import { ContentCopyRounded, DeleteRounded, EditRounded } from "@mui/icons-material";
import { MouseEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../store/hooks";

import { ExposedVTCardRefMethodsObject } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCard";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useGetAllGroupsQuery } from "../../services/users.service";
import { v4 as uuid } from "uuid";
import { ERole, IGroup } from "../../models/user.model";
import { IScenarioOverview } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import {
    useCreateScenarioMutation,
    useDeleteScenarioMutation,
    useDuplicateScenarioMutation,
    useGetScenariosQuery,
    useUpdateScenarioMutation,
} from "../../services/scenario.service";
import HomeCardGridElement from "../components/HomeCardGridElement";
// import { useGetBreakPointLabel } from "../../hooks/useGetBreakpointLabel";

// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components
const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        // border: `1px solid ${theme.palette.divider}`,
        borderRadius: "10px",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
    }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    backgroundColor: "#222326",
    height: "40vh",
    overflowY: "auto",
}));

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom
interface IProps {
    leftDrawWidth: number;
    searchValue: string;
    setScenarioDialog: (scenarioDialog: IScenarioOverview | undefined) => void;
    filterValue: "All Scenarios" | "Your Scenarios" | "Group Scenarios" | "New Scenario";
    scenarioLoading: string;
    setScenarioLoading: (scenarioLoading: string) => void;
    deleteScenario: any;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function HomeCardGridContainer({
    leftDrawWidth,
    searchValue,
    setScenarioDialog,
    filterValue,
    scenarioLoading,
    setScenarioLoading,
    deleteScenario,
}: IProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { id: userId } = useAppSelector((state) => state.userReducer);
    const { data: groups, isLoading: groupsLoading } = useGetAllGroupsQuery(userId);
    const isAboveMdScreenWidth = useMediaQuery(theme.breakpoints.up("md"));

    const breakPointLabel = useGetMuiBreakPointLabel();

    const { id } = useAppSelector((state) => state.userReducer);

    const vtCardRefs = useRef<Map<string, ExposedVTCardRefMethodsObject | null>>(new Map());

    const [createScenario] = useCreateScenarioMutation();
    const [duplicateScenario] = useDuplicateScenarioMutation();
    const { data: scenarios, isLoading } = useGetScenariosQuery(userId ? userId : "");

    const [windowWidth, windowHeight] = useOnWindowResize();

    // const currentMuiBreakPointLabel = useGetMuiBreakPointLabel();
    // const [maxCardHeight, setMaxCardHeight] = useState<number>();
    // useEffect(() => {
    //     if (vtCardRefs.current.size > 0) {
    //         window.setTimeout(() => {
    //             const largestCardHeight = Array.from(vtCardRefs.current).reduce((prevItemInMap, itemInMap) => {
    //                 if (itemInMap[1]?.getRootNode()?.clientHeight! > prevItemInMap[1]?.getRootNode()?.clientHeight!) {
    //                     return itemInMap;
    //                 } else {
    //                     return prevItemInMap;
    //                 }
    //             });
    //             setMaxCardHeight(largestCardHeight[1]?.getRootNode()?.clientHeight);
    //         }, 500);
    //     }
    // }, [scenarios, windowWidth]);

    return (
        <Grid
            container
            xs={12}
            sx={{
                marginLeft: `${leftDrawWidth + 30}px`,
                marginRight: "40px",
                marginTop: "20px",
                height: "calc(100% - 470px)",
                marginBottom: "100px",
            }}
            spacing={"10px"}
        >
            {isLoading ? (
                <>
                    {[...Array(8)].map(() => (
                        <Grid item xs={12} md={6} lg={4} xl={3} container sx={{ position: "relative" }}>
                            <VTCard
                                title={""}
                                loading
                                sx={{
                                    width: "100%",
                                    maxWidth: "none",
                                    height: "360px",
                                }}
                            />
                        </Grid>
                    ))}
                </>
            ) : (
                <>
                    {scenarios &&
                    scenarios.filter(
                        (filterElement: IScenarioOverview) =>
                            filterValue === "All Scenarios" || filterElement.owner === userId,
                    ).length === 0 ? (
                        <>
                            <Grid item xs={12} textAlign={"center"} sx={{ marginTop: "20px" }}>
                                <img src={NoScenarios} />
                                <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                    There are no scenarios available to you
                                </VTTypography>
                            </Grid>
                            {/* )} */}
                        </>
                    ) : (
                        <>
                            {scenarioLoading === "CreateScenario" && (
                                <Grid item xs={12} md={6} lg={4} xl={3} container sx={{ position: "relative" }}>
                                    <VTCard
                                        title={""}
                                        loading
                                        sx={{
                                            width: "100%",
                                            maxWidth: "none",
                                            height: "360px",
                                        }}
                                    />
                                </Grid>
                            )}

                            {scenarios &&
                                scenarios
                                    .filter(
                                        (filterElement: IScenarioOverview) =>
                                            (filterValue === "All Scenarios" || filterElement.owner === userId) &&
                                            filterElement.published?.published &&
                                            filterElement.title
                                                .toLocaleLowerCase()
                                                .includes(searchValue.toLocaleLowerCase()),
                                    )
                                    .sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf())
                                    .map((scenarioElement: IScenarioOverview, scenarioIndex: number) => (
                                        <HomeCardGridElement
                                            scenarioElement={scenarioElement}
                                            scenarioIndex={scenarioIndex}
                                            setScenarioDialog={setScenarioDialog}
                                            duplicateScenario={duplicateScenario}
                                            userId={userId}
                                            setScenarioLoading={setScenarioLoading}
                                            deleteScenario={deleteScenario}
                                            scenarios={scenarios}
                                            filterValue={filterValue}
                                            searchValue={searchValue}
                                            scenarioLoading={scenarioLoading}
                                            isAboveMdScreenWidth={isAboveMdScreenWidth}
                                            createScenario={createScenario}
                                            navigate={navigate}
                                        />
                                    ))}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
