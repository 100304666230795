import IconInfo from "../assets/icons/IconInfo";
import IconPrecision from "../assets/icons/IconPrecision";
import { SxProps } from "@mui/material";
import IconArchitecture from "../assets/icons/IconArchitecture";
import IconEngineering from "../assets/icons/IconEngineering";
import IconHealthAndSafety from "../assets/icons/IconHealthAndSaftey";
import IconHelp from "../assets/icons/IconHelp";
import IconSupport from "../assets/icons/IconSupport";
import IconMovement from "../assets/icons/IconMovement";
import IconArrows from "../assets/icons/IconArrows";
import IconFire from "../assets/icons/IconFire";
import IconDual from "../assets/icons/IconDual";
import IconReport from "../assets/icons/IconReport";
import IconStairs from "../assets/icons/IconStairs";
import IconTablet from "../assets/icons/IconTablet";
import IconTask from "../assets/icons/IconTask";
import IconWarning from "../assets/icons/IconWarning";
import IconArrowHead from "../assets/icons/IconArrowHead";

export function IconSwitch(param: string | undefined, sx?: SxProps) {
    switch (param) {
        case "Info":
            return IconInfo(sx);
        case "Movement":
            return IconMovement(sx);
        case "Arrows":
            return IconArrows(sx);
        case "Precision":
            return IconPrecision(sx);
        case "Support":
            return IconSupport(sx);
        case "Architecture":
            return IconArchitecture(sx);
        case "Engineering":
            return IconEngineering(sx);
        case "HealthAndSafety":
            return IconHealthAndSafety(sx);
        case "Help":
            return IconHelp(sx);
        case "Fire":
            return IconFire(sx);
        case "Dual":
            return IconDual(sx);
        case "Report":
            return IconReport(sx);
        case "Stairs":
            return IconStairs(sx);
        case "Tablet":
            return IconTablet(sx);
        case "Task":
            return IconTask(sx);
        case "Warning":
            return IconWarning(sx);
        case "ArrowHead":
            return IconArrowHead(sx);
        default:
            return undefined;
    }
}
