import { Grid } from "@react-three/drei";
import { useEffect } from "react";

interface IProps {}

export default function Passthrough({}: IProps) {
    return (
        <>
            <mesh>
                {/* R3F primitive geometry, what's default vals? think it's center [0, 0, 0] */}
                <sphereGeometry args={[500, 32, 16]} />
                <meshStandardMaterial color="blue" wireframe />
            </mesh>
        </>
    );
}
