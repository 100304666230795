import { VTHeadsetHotspot } from "@virtus-tech-repository/virtus-tech-repository";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { IconSwitch } from "../../helpers/IconSwitchHelper";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import {
    setCurrentAudio,
    setCurrentFeedbackImage,
    setCurrentFeedbackInput,
    setCurrentFeedbackVideo,
} from "../../store/slices/current.slice";
import { useNavigate } from "react-router-dom";
import { useThree } from "@react-three/fiber";

interface IProps {
    hotspot: IHotspot;
    currentOpenHotspot: string | undefined;
    setCurrentOpenHotspot: (currentOpenHotspot: string | undefined) => void;
    handleProceedVideo?: any;
    handleChangeScene?: any;
}
export default function HeadsetHotspotWrapper({
    hotspot,
    currentOpenHotspot,
    setCurrentOpenHotspot,
    handleProceedVideo,
    handleChangeScene,
}: IProps) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [hotspotOpen, setHotspotOpen] = useState<boolean>(false);
    const { gl } = useThree();

    const exitXR = async (gl: any) => {
        const session = gl.xr.getSession();
        if (session !== null) {
            await session.end();
        }
    };

    useEffect(() => {
        if (currentOpenHotspot && currentOpenHotspot !== hotspot.id && hotspotOpen) {
            setHotspotOpen(false);
        }
    }, [currentOpenHotspot]);

    return (
        <>
            {hotspotOpen ? (
                <VTHeadsetHotspot
                    title={hotspot.contents.postClickContent?.title}
                    icon={IconSwitch(hotspot.contents.postClickContent?.icon, {
                        height: "80px",
                        width: "80px",
                        color: `${
                            hotspot.styles?.postClickStyles?.colours?.text
                                ? `rgba(${hotspot.styles?.postClickStyles?.colours?.text.r}, ${hotspot.styles?.postClickStyles?.colours?.text.g}, ${hotspot.styles?.postClickStyles?.colours?.text.b}, ${hotspot.styles?.postClickStyles?.colours?.text.a})`
                                : "#fff"
                        }`,
                    })}
                    location={hotspot.location}
                    onClick={() => {
                        if (handleChangeScene && hotspot.actions?.movement?.sceneId) {
                            if (hotspot.actions?.movement?.sceneId !== "Scene Undefined") {
                                handleChangeScene(hotspot.actions?.movement?.sceneId, hotspot.actions?.movement?.delay);
                            }
                        }
                        if (hotspot.actions?.appFeedback?.audio) {
                            dispatch(setCurrentAudio(hotspot.actions?.appFeedback?.audio));
                        }
                        if (hotspot.actions?.appFeedback?.video) {
                            dispatch(setCurrentFeedbackVideo(hotspot.actions?.appFeedback?.video));
                        }
                        if (hotspot.actions?.appFeedback?.image) {
                            dispatch(setCurrentFeedbackImage(hotspot.actions?.appFeedback?.image));
                        }
                        if (hotspot.actions?.appFeedback?.input) {
                            dispatch(setCurrentFeedbackInput(hotspot.actions?.appFeedback?.input));
                        }
                        if (handleProceedVideo && hotspot.actions?.appFeedback?.proceed) {
                            handleProceedVideo(hotspot.actions?.appFeedback?.delay);
                        }
                        if (hotspot.actions?.navigate) {
                            window.open(hotspot.actions?.navigate);
                        }
                    }}
                    styles={hotspot.styles?.postClickStyles}
                />
            ) : (
                <VTHeadsetHotspot
                    title={hotspot.contents.preClickContent.title}
                    icon={IconSwitch(hotspot.contents.preClickContent.icon, {
                        height: "80px",
                        width: "80px",
                        color: `${
                            hotspot.styles?.postClickStyles?.colours?.text
                                ? `rgba(${hotspot.styles?.postClickStyles?.colours?.text.r}, ${hotspot.styles?.postClickStyles?.colours?.text.g}, ${hotspot.styles?.postClickStyles?.colours?.text.b}, ${hotspot.styles?.postClickStyles?.colours?.text.a})`
                                : "#fff"
                        }`,
                    })}
                    location={hotspot.location}
                    onClick={() => {
                        if (
                            hotspot.contents.postClickContent &&
                            (hotspot.contents.postClickContent?.title ||
                                hotspot.contents.postClickContent?.icon ||
                                hotspot.contents.postClickContent?.contentData)
                        ) {
                            setHotspotOpen(true);

                            if (setCurrentOpenHotspot) {
                                setCurrentOpenHotspot(hotspot.id);
                            }
                        }

                        if (hotspot.actions?.appFeedback?.audio) {
                            dispatch(setCurrentAudio(hotspot.actions?.appFeedback?.audio));
                        }
                        if (hotspot.actions?.appFeedback?.video) {
                            dispatch(setCurrentFeedbackVideo(hotspot.actions?.appFeedback?.video));
                        }
                        if (hotspot.actions?.appFeedback?.image) {
                            dispatch(setCurrentFeedbackImage(hotspot.actions?.appFeedback?.image));
                        }
                        if (hotspot.actions?.appFeedback?.input) {
                            dispatch(setCurrentFeedbackInput(hotspot.actions?.appFeedback?.input));
                        }
                        if (handleProceedVideo && hotspot.actions?.appFeedback?.proceed) {
                            handleProceedVideo(hotspot.actions?.appFeedback?.delay);
                        }
                        if (hotspot.actions?.navigate) {
                            if (hotspot.actions?.navigate === "/") {
                                exitXR(gl);
                                navigate("/");
                            } else {
                                window.open(hotspot.actions?.navigate);
                            }
                        }
                        if (hotspot.actions?.autoClose) {
                            const timer = setTimeout(
                                () => {
                                    setHotspotOpen(false);
                                    if (setCurrentOpenHotspot) {
                                        setCurrentOpenHotspot(undefined);
                                    }
                                },
                                hotspot.actions?.autoClose * 1000,
                            );
                            return () => clearTimeout(timer);
                        }
                        if (handleChangeScene && hotspot.actions?.movement?.sceneId) {
                            if (hotspot.actions?.movement?.sceneId !== "Scene Undefined") {
                                handleChangeScene(hotspot.actions?.movement?.sceneId, hotspot.actions?.movement?.delay);
                            }
                        }
                    }}
                    styles={hotspot.styles?.preClickStyles}
                />
            )}
        </>
    );
}
