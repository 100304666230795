import { useState } from "react";
import Login from "../views/pages/LoginPage";
import { useAppSelector } from "../store/hooks";

interface IProps {
    children: React.ReactNode;
}

export default function ProtectedRoute({ children }: IProps) {
    const { id } = useAppSelector((state) => state.userReducer);

    return <>{id !== "" ? children : <Login />} </>;
}
