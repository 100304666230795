import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum SelectedTheme {
    LIGHT,
    DARK,
}

export interface IThemeState {
    theme: SelectedTheme;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
const themeInitialState: IThemeState = {
    theme: SelectedTheme.DARK,
};

const themeSlice = createSlice({
    name: "theme",
    initialState: themeInitialState,
    reducers: {
        setTheme: (state: IThemeState, { payload }: PayloadAction<SelectedTheme>) => {
            state.theme = payload;
        },
    },
});

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
