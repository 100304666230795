//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IAPIResponse } from "@virtus-tech-repository/virtus-tech-repository/lib/models/api.model";
import { IScenario, IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";

const isDev: boolean = process.env.REACT_APP_IS_LOCAL === "true";

// Custom components imports (and hooks and helpers)
export const ScenarioAccessApi = createApi({
    reducerPath: "scenarioAccess",
    tagTypes: ["scenarioAccess"],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_NINJA_ENDPOINT,
    }),
    endpoints: (builder) => ({
        getScenarioAccess: builder.query<IScenario[], string>({
            query: (scenarioId) => ({
                url: `scenarioAccess/${scenarioId}`,
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }: IScenario) => ({
                              type: "scenarioAccess" as const,
                              id,
                          })),
                          { type: "scenarioAccess", id: "LIST" },
                      ]
                    : [{ type: "scenarioAccess", id: "LIST" }],
        }),
        updateScenarioAccess: builder.mutation<IAPIResponse, { scenarioId: string; accessList: string[] }>({
            query: ({ scenarioId, accessList }) => ({
                url: `scenarioAccess/${scenarioId}`,
                method: "POST",
                body: accessList,
            }),
            invalidatesTags: [{ type: "scenarioAccess", id: "LIST" }],
        }),

        // deleteScenario: builder.mutation<IAPIResponse, string>({
        //     query: (body) => ({
        //         url: `scenario/${body}`,
        //         method: "DELETE",
        //     }),
        //     invalidatesTags: [{ type: "scenario", id: "LIST" }],
        // }),
    }),
});

export const { useGetScenarioAccessQuery, useUpdateScenarioAccessMutation } = ScenarioAccessApi;
