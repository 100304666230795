import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { setMediaUploadPercentage } from "../slices/mediaUpload.slice";

export interface ISignedUrlResponse {
    uploadURL: string;
    Key: string;
}

export const uploadMedia = createAsyncThunk("UploadMedia", async ({ media, url, dispatch }: any): Promise<any> => {
    const options: any = {
        method: "PUT",
        url: url,
        headers: { "Content-Type": "image/*" },
        data: media,
        onUploadProgress: (progressEvent: any) => {
            let percentComplete: number = progressEvent.loaded / progressEvent.total;

            dispatch(setMediaUploadPercentage(percentComplete * 100));
        },
    };

    const response: AxiosResponse<ISignedUrlResponse> = await axios.request(options);

    return response.data;
});
