//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { IAPIResponse } from "@virtus-tech-repository/virtus-tech-repository/lib/models/api.model";

const isDev: boolean = process.env.REACT_APP_IS_LOCAL === "true";

// Custom components imports (and hooks and helpers)
export const HotspotApi = createApi({
    reducerPath: "hotspot",
    tagTypes: ["hotspot", "SceneHotspots", "groupHotspot"],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_NINJA_ENDPOINT,
    }),
    endpoints: (builder) => ({
        getHotspots: builder.query<IHotspot[], string>({
            query: (sceneId) => ({
                url: `scene/hotspots/${sceneId}`,
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }: IHotspot) => ({
                              type: "hotspot" as const,
                              id,
                          })),
                          { type: "SceneHotspots", id: "LIST" },
                      ]
                    : [{ type: "SceneHotspots", id: "LIST" }],
        }),
        createHotspot: builder.mutation<IAPIResponse, { sceneId: string; hotspot: IHotspot }>({
            query: ({ sceneId, hotspot }) => ({
                url: `hotspot/${sceneId}`,
                method: "POST",
                body: hotspot,
            }),
            invalidatesTags: [{ type: "SceneHotspots", id: "LIST" }],
        }),
        updateHotspot: builder.mutation<IAPIResponse, Partial<IHotspot>>({
            query: (body) => ({
                url: `hotspot/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: [{ type: "SceneHotspots", id: "LIST" }],
        }),
        deleteHotspot: builder.mutation<IAPIResponse, { sceneId: string; hotspotId: string }>({
            query: ({ sceneId, hotspotId }) => ({
                url: `hotspot/${sceneId}/${hotspotId}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "SceneHotspots", id: "LIST" }],
        }),

        getGroupHotspots: builder.query<IHotspot[], string>({
            query: (groupId) => ({
                url: `hotspot/group/${groupId}`,
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }: IHotspot) => ({
                              type: "hotspot" as const,
                              id,
                          })),
                          { type: "groupHotspot", id: "LIST" },
                      ]
                    : [{ type: "groupHotspot", id: "LIST" }],
        }),
        createGroupHotspot: builder.mutation<IAPIResponse, { groupId: string; hotspot: IHotspot }>({
            query: ({ groupId, hotspot }) => ({
                url: `hotspot/group/${groupId}`,
                method: "POST",
                body: hotspot,
            }),
            invalidatesTags: [{ type: "groupHotspot", id: "LIST" }],
        }),
        updateGroupHotspot: builder.mutation<IAPIResponse, Partial<IHotspot>>({
            query: (body) => ({
                url: `hotspot/group/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: [
                { type: "groupHotspot", id: "LIST" },
                { type: "SceneHotspots", id: "LIST" },
            ],
        }),
        deleteGroupHotspot: builder.mutation<IAPIResponse, { groupId: string; hotspotId: string }>({
            query: ({ groupId, hotspotId }) => ({
                url: `hotspot/group/${groupId}/${hotspotId}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "groupHotspot", id: "LIST" }],
        }),
    }),
});

export const {
    useGetHotspotsQuery,
    useCreateHotspotMutation,
    useUpdateHotspotMutation,
    useDeleteHotspotMutation,
    useGetGroupHotspotsQuery,
    useCreateGroupHotspotMutation,
    useUpdateGroupHotspotMutation,
    useDeleteGroupHotspotMutation,
} = HotspotApi;
