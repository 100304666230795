//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect, useState } from "react";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "typescript-cookie";

// Material ui Imports
import { Box, CircularProgress, Grid, Paper, Typography, useTheme } from "@mui/material";

// Custom components imports (and hooks and helpers)
import { VTButton, VTLoginForm, VTLogo, VTNavBar } from "@virtus-tech-repository/virtus-tech-repository";
import { useAppDispatch } from "../../store/hooks";
import {
    LoginInputsState,
    ResetCodeInputFormState,
    ResetPasswordState,
    VTConfirmPasswordFormWithdRulesWarning,
    VTResetCodeFormWithResendMsg,
    VTResetPasswordForm,
} from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTForm";
import awsAuthHelper, {
    AuthStatePasswordChangeResult,
    AuthStateSignInResult,
    AuthStateTermsAndConditionsResult,
} from "../../helpers/awsAuthHelper";
import { setUser } from "../../store/slices/user.slice";
import sleep from "../../utils/sleep";

export function LoginContainer() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const theme = useTheme();
    const [checkingPreAuthenticatedUser, setCheckingPreAuthenticatedUser] = useState(true);

    const [selectedScreen, setSelectedScreen] = useState<
        "login" | "resetCode" | "inputCode" | "changePassword" | "termsAndConditions" | "lti_login"
    >("login");

    const [isWrongLoginDetails, setIsWrongLoginDetails] = useState<boolean>(false);

    const [createFirstTimePassword, setCreateFirstTimePassword] = useState<boolean>(false);

    const [userEmail, setUserEmail] = useState<string>("");
    const [userPassword, setUserPassword] = useState<string>("");

    const [inputResetCode, setInputResetCode] = useState<string>("");

    const [resetCodeEmailInputErrorMsg, setResetCodeEmailInputErrorMsg] = useState<string | undefined>(undefined);

    const [LTILoginError, setLTILoginError] = useState<boolean>(false);

    const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);

    // LTI Login Details
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const lti = query.get("lti");

    useEffect(() => {
        setResetCodeEmailInputErrorMsg(undefined);
        setIsWrongLoginDetails(false);
    }, [selectedScreen]);

    const authenticate = async () => {
        if (lti) {
            const ltiEmail = getCookie("email") ?? "";
            const ltiCookie = getCookie("token") ?? "";

            setSelectedScreen("lti_login");
            const LTILoginResponse = await awsAuthHelper.ltiLogin(
                ltiEmail,
                ltiCookie,
                (userId: string, userEmail: string, organisation: string) => {
                    dispatch(
                        setUser({
                            id: userId,
                            email: userEmail,
                            name: "",
                            organisation: organisation,
                        }),
                    );
                    navigate("/");
                },
            );

            if (LTILoginResponse === AuthStateSignInResult.LTI_LOGIN_FAIL) {
                setLTILoginError(true);
            }
        } else {
            try {
                await awsAuthHelper.checkUserCurrentlyAuthenticated(
                    (userId: string, userEmail: string, organisation: string) => {
                        dispatch(
                            setUser({
                                id: userId,
                                email: userEmail,
                                name: "",
                                organisation: organisation,
                            }),
                        );
                    },
                );
                setCheckingPreAuthenticatedUser(false);
            } catch (e) {
                setCheckingPreAuthenticatedUser(false);
            }
        }
    };

    // check if user is already authenticated
    useEffect(() => {
        (async () => await authenticate())(); //IIFE pattern
    }, []);

    return (
        <Grid
            container
            xs={12}
            sx={{
                marginTop: "100px",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            {checkingPreAuthenticatedUser ? (
                <Grid item sx={{ marginTop: "50px" }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <>
                    {selectedScreen === "lti_login" && (
                        <Grid item sx={{ marginTop: "50px" }}>
                            {LTILoginError ? <Typography> Login Error</Typography> : <CircularProgress />}
                        </Grid>
                    )}
                    {selectedScreen === "login" && (
                        <VTLoginForm
                            loadingSpinner={showLoadingSpinner}
                            inputFieldsProps={{
                                errorMessage: isWrongLoginDetails ? "wrong email or password entered" : undefined,
                                onInputsChanged: () => {
                                    if (isWrongLoginDetails) {
                                        setIsWrongLoginDetails(false);
                                    }
                                },
                            }}
                            sx={{ width: "470px", marginTop: "10px" }}
                            primaryButtonText={"login"}
                            secondaryButtonText={"forgot password"}
                            onSecondaryButtonClicked={function (formDataInputObj: LoginInputsState): void {
                                setUserEmail(formDataInputObj.login);
                                setSelectedScreen("resetCode");
                            }}
                            title={"Login"}
                            onSubmit={async function (formFieldsStateObject: LoginInputsState) {
                                setShowLoadingSpinner(true);

                                setUserEmail(formFieldsStateObject.login);
                                let result = await awsAuthHelper.signIn(
                                    formFieldsStateObject.login,
                                    formFieldsStateObject.password,
                                    (userId: string, email: string, organisation: string) => {
                                        dispatch(
                                            setUser({
                                                id: userId,
                                                name: "",
                                                email: email,
                                                organisation: organisation,
                                            }),
                                        );
                                    },
                                );

                                await sleep(2000);

                                if (result === AuthStateSignInResult.LOGIN_FAILURE) {
                                    setIsWrongLoginDetails(true);
                                    setShowLoadingSpinner(false);
                                } else {
                                    setIsWrongLoginDetails(false);
                                    setShowLoadingSpinner(false);
                                }

                                if (result === AuthStateSignInResult.LOGIN_RESET_PASSWORD) {
                                    setCreateFirstTimePassword(true);
                                    setShowLoadingSpinner(false);
                                    setSelectedScreen("changePassword");
                                }

                                // TODO: REMOVE THIS IN FUTURE, THIS IS TEMPORARY LOGIN FOR DEMO'ING PURPOSES!!!
                                if (result === AuthStateSignInResult.LOGIN_TERMS_AND_CONDITIONS) {
                                    // navigate("/");
                                    await authenticate();
                                }
                            }}
                        />
                    )}

                    {selectedScreen === "resetCode" && (
                        <VTResetPasswordForm
                            inputFieldsProps={{
                                errorMessage: resetCodeEmailInputErrorMsg,
                                initialEmailVal: userEmail,
                                onEmailChange(val) {
                                    setResetCodeEmailInputErrorMsg(undefined);
                                },
                            }}
                            primaryButtonText={"send email"}
                            secondaryButtonText={"back"}
                            onSecondaryButtonClicked={function (formDataInputObj: ResetPasswordState): void {
                                setSelectedScreen("login");
                            }}
                            title={"Reset Password"}
                            onSubmit={async function (formFieldsStateObject: ResetPasswordState) {
                                const result = await awsAuthHelper.checkUserExistsAndSendPasswordResetCode(
                                    formFieldsStateObject.email,
                                );
                                if (result) {
                                    setUserEmail(formFieldsStateObject.email);
                                    setSelectedScreen("inputCode");
                                } else {
                                    setResetCodeEmailInputErrorMsg("user doesn't exist");
                                }
                            }}
                            sx={{ width: "470px", marginTop: "10px" }}
                        />
                    )}

                    {selectedScreen === "inputCode" && (
                        <VTResetCodeFormWithResendMsg
                            displayResendContent
                            onSubmit={function (formFieldsStateObject: ResetCodeInputFormState): void {
                                if (!formFieldsStateObject.codeIsValid) return;
                                setInputResetCode(formFieldsStateObject.code);
                                setSelectedScreen("changePassword");
                            }}
                            onCancel={function (formFieldsStateObject: ResetCodeInputFormState): void {
                                setSelectedScreen("resetCode");
                            }}
                            onResend={async function () {
                                const result = await awsAuthHelper.checkUserExistsAndSendPasswordResetCode(userEmail);
                            }}
                            sx={{ width: "470px", marginTop: "10px" }}
                        />
                    )}

                    {/* TODO: handle change password success result correctly, wether it's first time password creation or password change */}
                    {selectedScreen === "changePassword" && (
                        <VTConfirmPasswordFormWithdRulesWarning
                            onSubmit={async (passwordIsValid: boolean, password?: string | undefined) => {
                                if (passwordIsValid && password) {
                                    setUserPassword(password);
                                    if (createFirstTimePassword) {
                                        const result = await awsAuthHelper.createPasswordFirstTime(password);

                                        if (result === AuthStatePasswordChangeResult.SUCCESS) {
                                            const signInResult = await awsAuthHelper.signIn(
                                                userEmail,
                                                password,
                                                () => {},
                                            );
                                            if (signInResult === AuthStateSignInResult.LOGIN_TERMS_AND_CONDITIONS) {
                                                setSelectedScreen("termsAndConditions");
                                            }
                                        }
                                    } else {
                                        const resetPasswordResult = await awsAuthHelper.resetPasswordIsSuccess(
                                            userEmail,
                                            inputResetCode,
                                            password,
                                        );

                                        if (resetPasswordResult === AuthStatePasswordChangeResult.SUCCESS) {
                                            await awsAuthHelper.signIn(
                                                userEmail,
                                                password,
                                                (userId: string, userEmail: string, organisation: string) => {
                                                    dispatch(
                                                        setUser({
                                                            id: userId,
                                                            email: userEmail,
                                                            name: "",
                                                            organisation: organisation,
                                                        }),
                                                    );
                                                },
                                            );
                                        } else if (
                                            resetPasswordResult ===
                                            AuthStatePasswordChangeResult.WRONG_CODE_ENETERD_ERROR
                                        ) {
                                        } else {
                                        }
                                    }
                                }
                            }}
                            onSecondaryButtonClicked={function (): void {
                                // setSelectedScreen("resetCode");
                                if (createFirstTimePassword) {
                                    setSelectedScreen("login");
                                } else {
                                    setSelectedScreen("inputCode");
                                }
                            }}
                            sx={{ width: "470px", marginTop: "10px" }}
                        />
                    )}

                    {selectedScreen === "termsAndConditions" && (
                        <>
                            <Typography variant="h6" sx={{ margin: "10px" }}>
                                Terms and Conditions for Digi Sim
                            </Typography>
                            <Paper
                                sx={{
                                    minWidth: "150px",
                                    maxWidth: "760px",
                                    padding: "10px",
                                    paddingBottom: "22px",
                                    height: "60vh",
                                }}
                            >
                                <Box component="div" sx={{ overflow: "scroll", height: "100%" }}>
                                    <Typography variant="body1" style={{ padding: "20px" }}>
                                        <h1>Welcome to Virtus Tech's DIGI SIM Platform</h1>
                                        <p>
                                            These Terms of Use ("Terms") govern your ("you" or "your") utilization of
                                            Virtus Tech's DIGI SIM platform (the "Service"). By using the Service, you
                                            agree that these terms will constitute a legally binding agreement between
                                            you and Virtus Tech.
                                        </p>

                                        <h2>Using the Service: Access to the Service</h2>
                                        <p>
                                            You are granted a non-exclusive, limited, non-transferable, revocable
                                            license to access and utilize the Service for business or personal purposes,
                                            subject to your adherence to these Terms. Virtus Tech reserves all rights
                                            not expressly granted herein. Each user must possess a unique account, and
                                            you are accountable for any activity conducted on your account. Sharing your
                                            unique username, password, or other security code with any other party is
                                            strictly prohibited.
                                        </p>

                                        <h2>Acceptable Use Policy</h2>
                                        <p>
                                            Your utilization of the Service, your User Content, and your Designs must
                                            align with Virtus Tech's Acceptable Use Policy. Failure to comply with any
                                            provision of this policy may result in the deletion of violating User
                                            Content and/or Designs, or the suspension or termination of your account
                                            with immediate effect.
                                        </p>

                                        <h2>Anti-discrimination</h2>
                                        <p>
                                            Virtus Tech vehemently opposes the use of its Service for discriminatory
                                            purposes, particularly based on race, religion, sex, sexual orientation,
                                            age, disability, ancestry, or national origin. You are strictly prohibited
                                            from using the Service in any manner that would incite, promote, or support
                                            such discrimination. Additionally, the Service must not be utilized to
                                            incite or promote hostility or violence.
                                        </p>

                                        <h2>Restrictions on Use of the Service</h2>
                                        <p>
                                            You shall not, either directly or through any third party: rent, sell,
                                            distribute, sublicense, or otherwise make available the Service or the
                                            Licensed Content to any third party; copy, decompile, reverse-engineer,
                                            modify, or create derivative works of the Service; access the Service for
                                            purposes of performance benchmarking or building competitive products;
                                            transmit viruses or malicious code; use a VPN to circumvent geographic-based
                                            pricing or content access; engage in spamming or unsolicited emails; conduct
                                            data mining, extraction, or scraping; or bypass measures implemented to
                                            prevent or restrict access to the Service.
                                        </p>

                                        <h2>Security and Data Privacy</h2>
                                        <p>
                                            Virtus Tech implements physical, technical, and administrative security
                                            measures to safeguard your information from unauthorized access,
                                            destruction, use, modification, or disclosure. Further details on how Virtus
                                            Tech protects the Service can be obtained by contacting{" "}
                                            <a
                                                style={{
                                                    background: theme.palette.primary.light,
                                                    textDecoration: "none",
                                                    color: "#eee",
                                                }}
                                                href="mailto:info@virtustech.co.uk"
                                            >
                                                info@virtustech.co.uk
                                            </a>
                                            .
                                        </p>

                                        <h2>Data Privacy</h2>
                                        <p>
                                            Virtus Tech's Privacy Policy outlines how personal data is collected, used,
                                            transferred, disclosed, and stored. By creating a Virtus Tech's DIGI SIM
                                            account, you confirm your agreement to our Data Processing Addendum, which
                                            is incorporated into these Terms to the extent applicable.
                                        </p>

                                        <h2>Content and Designs</h2>
                                        <ul>
                                            <li>
                                                User Content: You affirm ownership of your User Content or have secured
                                                necessary rights. You grant Virtus Tech a license to utilize your User
                                                Content to provide the Service.
                                            </li>
                                            <li>
                                                Licensed Content: You may use Licensed Content subject to additional
                                                license rights and restrictions specified in the Content License
                                                Agreement.
                                            </li>
                                            <li>
                                                Designs: Your Designs may include a combination of User Content and
                                                Licensed Content.
                                            </li>
                                        </ul>

                                        <h2>Data Ownership</h2>
                                        <p>
                                            You retain ownership of all data uploaded to our platform. However, by
                                            uploading data to our platform, you grant us a non-exclusive, worldwide,
                                            royalty-free license to use, reproduce, modify, and distribute your data for
                                            the purpose of providing our services and improving our platform.
                                        </p>

                                        <h2>Intellectual Property</h2>
                                        <p>
                                            All intellectual property rights in the Service and Licensed Content remain
                                            the property of Virtus Tech and its licensors. Feedback provided to Virtus
                                            Tech relating to the Service or its products becomes the property of Virtus
                                            Tech.
                                        </p>

                                        <h2>Warranty Disclaimer</h2>
                                        <p>
                                            The Service is provided "as-is" and "as-available." Virtus Tech disclaims
                                            all warranties, including merchantability and fitness for a particular
                                            purpose. Virtus Tech does not guarantee uninterrupted or error-free use of
                                            the Service.
                                        </p>

                                        <h2>Third Party Services</h2>
                                        <p>
                                            Your use of Third Party Services in conjunction with the Service is subject
                                            to the terms and conditions applicable to those services. Virtus Tech
                                            disclaims all liability arising from your use of Third Party Services.
                                        </p>

                                        <h2>Your Indemnity Obligations</h2>
                                        <p>
                                            You agree to defend, indemnify, and hold harmless Virtus Tech and its
                                            affiliates from claims resulting from your violation of these Terms or your
                                            User Content.
                                        </p>

                                        <h2>Limitation of Liability</h2>
                                        <p>
                                            Virtus Tech's aggregate cumulative liability under these Terms is limited.
                                            Virtus Tech is not liable for consequential, incidental, indirect, special,
                                            exemplary, or punitive damages.
                                        </p>

                                        <h2>Conclusion</h2>
                                        <p>
                                            These terms delineate your rights and responsibilities when using Virtus
                                            Tech's DIGI SIM platform. Should you have any questions or concerns, please
                                            contact us at{" "}
                                            <a
                                                style={{
                                                    background: theme.palette.primary.light,
                                                    textDecoration: "none",
                                                    color: "#eee",
                                                }}
                                                href="mailto:info@virtustech.co.uk"
                                            >
                                                info@virtustech.co.uk
                                            </a>
                                            .
                                        </p>
                                    </Typography>
                                </Box>
                            </Paper>
                            <VTButton
                                sx={{ marginTop: "20px" }}
                                onClick={async () => {
                                    const termsAndConditionsAcceptResult =
                                        await awsAuthHelper.acceptTermsAndConditions();

                                    if (termsAndConditionsAcceptResult === AuthStateTermsAndConditionsResult.ACCEPTED) {
                                        const signInResult = await awsAuthHelper.signIn(
                                            userEmail,
                                            userPassword,
                                            (userId: string, userEmail: string, organisation: string) => {
                                                dispatch(
                                                    setUser({
                                                        id: userId,
                                                        email: userEmail,
                                                        name: "",
                                                        organisation: organisation,
                                                    }),
                                                );
                                            },
                                        );
                                    }
                                }}
                            >
                                accept
                            </VTButton>
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
