//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Material ui Imports
// Custom components imports (and hooks and helpers)

import {
    ICameraTracking,
    ITracking,
    ITrackingActions,
    ITrackingHotspotAction,
    ITrackingState,
} from "../../models/tracking.models";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
const trackingInitialState: ITrackingState = {
    attempt: {} as ITracking,
    sceneActions: {} as ITrackingActions,
    hotspotActions: [],
};

const trackingReducer = createSlice({
    name: "tracking",
    initialState: trackingInitialState,
    reducers: {
        setAttempt: (state: ITrackingState, { payload }: PayloadAction<ITracking>) => {
            state.attempt = payload;
        },
        setSceneActions: (state: ITrackingState, { payload }: PayloadAction<ITrackingActions>) => {
            state.sceneActions = payload;
        },
        setHotspotActions: (state: ITrackingState, { payload }: PayloadAction<ITrackingHotspotAction>) => {
            state.hotspotActions = [...state.hotspotActions, payload];
        },
        clearHotspotActions: (state: ITrackingState) => {
            state.hotspotActions = [];
        },
    },
});

export const { setAttempt, setSceneActions, setHotspotActions, clearHotspotActions } = trackingReducer.actions;

export default trackingReducer.reducer;
