//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
// Custom components imports (and hooks and helpers)
import { v4 as uuid } from "uuid";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { useAppSelector } from "../store/hooks";
import { IScenarioOverview, IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Renders the different hotspots
export function blankScenarioOverview(id: string): IScenarioOverview {
    //-------------------------------------------------------------------------------------------------
    // ## Declares ##
    const newScenarioOverview: IScenarioOverview = {
        id: uuid(),
        title: "",
        description: "",
        previewImage: "",
        admins: [],
        viewers: [],
        tags: [],
        locked: false,
        owner: id,
        publishedVersion: "latests",
        date: new Date(),
    };

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return newScenarioOverview;
}

interface IBlankSceneProps {
    newSrc: string;
    type: EDraggableOptions;
    id: string;
    orderId: number;
    previewName?: string;
    startLocation?: any[];
}

export function blankScene({
    newSrc,
    type,
    id,
    orderId,
    previewName,
    startLocation = [0.1, 0.1, 10],
}: IBlankSceneProps): IScene {
    //-------------------------------------------------------------------------------------------------
    // ## Declares ##
    const newScene: IScene = {
        id: id,
        name: previewName ? previewName : "New Scene",
        src: newSrc,
        mediaType: type as EDraggableOptions,
        hotspots: [],
        context: {},
        startLocation: startLocation,
        // order: new Date().valueOf(),
        order: orderId,
    };

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return newScene;
}
