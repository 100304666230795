//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ITracking } from "../models/tracking.models";

const isDev: boolean = process.env.REACT_APP_IS_DEV === "true";

// Custom components imports (and hooks and helpers)
export const TrackingApi = createApi({
    reducerPath: "tracking",
    tagTypes: ["tracking"],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_NINJA_ENDPOINT,
    }),
    endpoints: (builder) => ({
        updateTrackingData: builder.mutation<ITracking, any>({
            query: (data) => ({
                url: `scenarioTracking/${data.scenarioId}/${data.userId}/${data.attemptId}`,
                method: "POST",
                body: data.body,
            }),
            invalidatesTags: [{ type: "tracking", id: "LIST" }],
        }),
        getScenarioAttempts: builder.query<ITracking[], any>({
            query: (data) => ({
                url: `scenarioTracking/${data.scenarioId}/${data.userId}`,
                method: "GET",
            }),
        }),
        getUserAttempts: builder.query<string[], any>({
            query: (data) => ({
                url: `scenarioTracking/${data}`,
                method: "GET",
            }),
        }),
    }),
});

export const { useUpdateTrackingDataMutation, useGetScenarioAttemptsQuery, useGetUserAttemptsQuery } = TrackingApi;
