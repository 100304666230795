//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { uploadMedia } from "../thunks/upload.thunks";
import { IMediaState } from "../../models/media.models";

const mediaUploadInitialState: IMediaState = {
    // This is between 0 and 100
    mediaUploadPercentage: 0,
    mediaUploading: false,
    mediaUploadCompleted: false,
    mediaUploadError: false,
};

const mediaUploadReducer = createSlice({
    name: "mediaUpload",
    initialState: mediaUploadInitialState,
    reducers: {
        setMediaUploadPercentage: (state: IMediaState, { payload }: PayloadAction<number>) => {
            state.mediaUploadPercentage = payload;
        },
        setMediaUploadCleanUp: (state: IMediaState) => {
            state.mediaUploadPercentage = 0;
            state.mediaUploading = false;
            state.mediaUploadCompleted = false;
            state.mediaUploadError = false;
        },
    },
    extraReducers(builder) {
        builder.addCase(uploadMedia.rejected, (state: IMediaState) => {
            state.mediaUploading = false;
            state.mediaUploadCompleted = false;
            state.mediaUploadError = true;
        });
        builder.addCase(uploadMedia.pending, (state: IMediaState) => {
            state.mediaUploading = true;
            state.mediaUploadCompleted = false;
            state.mediaUploadError = false;
        });
        builder.addCase(uploadMedia.fulfilled, (state: IMediaState) => {
            state.mediaUploading = false;
            state.mediaUploadCompleted = true;
            state.mediaUploadError = false;
        });
    },
});

export const { setMediaUploadPercentage, setMediaUploadCleanUp } = mediaUploadReducer.actions;

export default mediaUploadReducer.reducer;
