//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { Grid, SxProps, TextField, Tooltip, useMediaQuery } from "@mui/material";
import { VTButton } from "@virtus-tech-repository/virtus-tech-repository";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentAudio, setCurrentScene } from "../../store/slices/current.slice";
import {
    AddRounded,
    CancelRounded,
    DeleteRounded,
    EditRounded,
    SaveRounded,
    ArrowForwardRounded,
    Volcano,
    VolumeUpRounded,
    CloseRounded,
    VolumeOffRounded,
} from "@mui/icons-material";
import StopCircleRoundedIcon from "@mui/icons-material/StopCircleRounded";
import { Params, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useUpdateSceneMutation } from "../../services/scene.service";
import { useUpdateHotspotMutation } from "../../services/hotspot.service";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";

// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    audio: string;
    question?: IHotspot;
    setQuestion?: (question: IHotspot) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTCreatorAudioControls({ audio, question, setQuestion }: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { sceneId }: Readonly<Params<string>> = useParams();
    const dispatch = useAppDispatch();

    const [enhance, setEnhance] = useState<boolean>(true);
    const [updateScene] = useUpdateSceneMutation();
    const [updateHotspot] = useUpdateHotspotMutation();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { currentHotspot, currentAudio, currentScene } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    useEffect(() => {
        if (currentAudio) {
            setEnhance(true);
        } else {
            setEnhance(false);
        }
    }, [currentAudio]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Render for the tab buttons

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show creator page tabs options
    return (
        <>
            <Tooltip
                sx={{
                    position: "absolute",
                    top: currentHotspot.currentHotspot ? "85px" : "15px",
                    right: "15px",
                    zIndex: 2,
                }}
                title={currentAudio ? "Stop Playing Audio" : "Play Audio"}
                placement={"left"}
            >
                <Grid item>
                    {currentAudio ? (
                        <VTButton
                            type={"icon"}
                            onClick={() => dispatch(setCurrentAudio(""))}
                            sx={enhance ? { boxShadow: "0 0 18px #fff", transition: "1s" } : { transition: "1s" }}
                        >
                            <StopCircleRoundedIcon />
                        </VTButton>
                    ) : (
                        <VTButton
                            type={"icon"}
                            onClick={() => dispatch(setCurrentAudio(audio))}
                            sx={enhance ? { boxShadow: "0 0 18px #fff", transition: "1s" } : { transition: "1s" }}
                        >
                            <VolumeUpRounded />
                        </VTButton>
                    )}
                </Grid>
            </Tooltip>

            <Tooltip
                sx={{
                    position: "absolute",
                    top: currentHotspot.currentHotspot ? "135px" : "65px",
                    right: "15px",
                    zIndex: 2,
                }}
                title={"Delete Audio"}
                placement={"left"}
            >
                <Grid item>
                    <VTButton
                        type={"icon"}
                        onClick={() => {
                            if (question) {
                                updateHotspot({
                                    ...question,
                                    contents: {
                                        ...question.contents,
                                        preClickContent: {
                                            ...question.contents.preClickContent,
                                            question: {
                                                ...question.contents.preClickContent.question,
                                                time: question.contents.preClickContent.question!.time,
                                                answers: [],
                                                audio: "",
                                            },
                                        },
                                    },
                                });
                                if (setQuestion) {
                                    setQuestion({
                                        ...question,
                                        contents: {
                                            ...question.contents,
                                            preClickContent: {
                                                ...question.contents.preClickContent,
                                                question: {
                                                    ...question.contents.preClickContent.question,
                                                    time: question.contents.preClickContent.question!.time,
                                                    answers: [],
                                                    audio: "",
                                                },
                                            },
                                        },
                                    });
                                }
                            } else {
                                updateScene({ ...currentScene, audio: "" });
                            }
                        }}
                        sx={enhance ? { boxShadow: "0 0 18px #fff", transition: "1s" } : { transition: "1s" }}
                    >
                        <DeleteRounded />
                    </VTButton>
                </Grid>
            </Tooltip>
        </>
    );
}
