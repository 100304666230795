//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Material ui Imports
// Custom components imports (and hooks and helpers)
import { IUser } from "../../models/user.model";
import { EOrganisation } from "../../models/organisations.model";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
const userInitialState: IUser = {
    id: "",
    name: "",
    email: "",
    organisation: "",
};

const userReducer = createSlice({
    name: "user",
    initialState: userInitialState,
    reducers: {
        setUserId: (state: IUser, { payload }: PayloadAction<string>) => {
            state.id = payload;
        },

        setUserName: (state: IUser, { payload }: PayloadAction<string>) => {
            state.name = payload;
        },

        setUserEmail: (state: IUser, { payload }: PayloadAction<string>) => {
            state.email = payload;
        },

        setUserOrg: (state: IUser, { payload }: PayloadAction<string>) => {
            state.organisation = payload;
        },

        setUser: (state: IUser, { payload }: PayloadAction<IUser>) => {
            // TODO: for some reason state = payload does not work!
            state.id = payload.id;
            state.name = payload.name;
            state.email = payload.email;
            state.organisation = payload.organisation;
        },
    },
});

export const { setUserId, setUserName, setUserEmail, setUser, setUserOrg } = userReducer.actions;

export default userReducer.reducer;
