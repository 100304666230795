import { useHelper } from "@react-three/drei";
import { useRef } from "react";
import { CameraHelper } from "three";
import * as THREE from "three";

export default function CameraWireFrameHelper({ onClick }: any) {
    const camera = useRef<THREE.PerspectiveCamera>();

    // @ts-ignore
    useHelper(camera, CameraHelper);

    return (
        <group onClick={onClick} position={[0, 1.5, 4.5]}>
            <mesh
                geometry={new THREE.BoxGeometry(1, 1)}
                material={
                    new THREE.MeshBasicMaterial({
                        // color: 0xaaaaaa,
                        // wireframe: true,
                        visible: false,
                    })
                }
                position={[0, 0, -0.5]}
                scale={1}
            />
            <perspectiveCamera
                makeDefault={false}
                near={0.3}
                far={0.7}
                // @ts-ignore
                ref={camera}
            >
                <meshBasicMaterial />
            </perspectiveCamera>
        </group>
    );
}
