export function textCutOffAddElipsis(text: string, cutOffPointCharIndex: number) {
    const charAtIndex = text.charAt(cutOffPointCharIndex);
    if (charAtIndex === " ") {
        return text.substring(0, cutOffPointCharIndex) + "...";
    } else if (charAtIndex === "") {
        return text;
    } else {
        return text.substring(0, cutOffPointCharIndex + 1) + "...";
    }
}
