import { useState } from "react";
import Box from "@mui/material/Box";
import { alpha, Grid, ImageList, ImageListItem, SxProps, useTheme } from "@mui/material";
import { ArrowBackIosRounded, ArrowForwardIosRounded, CloseRounded } from "@mui/icons-material";

import VTStepperItem from "./VTStepperItem";
import { VTButton } from "@virtus-tech-repository/virtus-tech-repository";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";

interface IVTStepperProps {
    stepperArray: IScene[];
    mediaType?: EDraggableOptions;
    currentSceneId: string;
    handleItemClicked: (id: string) => void;
    disabled?: boolean;
    imageOverride?: any;
}

export default function VTStepper({
    stepperArray,
    mediaType,
    currentSceneId,
    handleItemClicked,
    disabled = false,
    imageOverride,
}: IVTStepperProps) {
    const [stepperOpen, setStepperOpen] = useState<boolean>(true);
    const theme = useTheme();
    return (
        <>
            {/*--------------------------------------------------------------------------------------------------*/}
            {/* Open Stepper Button */}
            <VTButton
                sx={{
                    backgroundColor: alpha(theme.palette.background.paper, 0.6),
                    borderRadius: "0px 15px 15px 0px",
                    ":hover": {
                        backgroundColor: alpha(theme.palette.background.paper, 0.8),
                    },
                    position: "absolute",
                    bottom: "100px",
                    zIndex: 5,
                    height: "50px",
                    width: "15px",
                    left: 0,

                    transform: stepperOpen ? "translateX(-100px)" : 0,
                    transition: "1s",
                }}
                onClick={() => setStepperOpen(true)}
            >
                <ArrowForwardIosRounded />
            </VTButton>

            {/*--------------------------------------------------------------------------------------------------*/}
            {/* Stepper Container */}
            <Box
                component={"div"}
                sx={{
                    left: "50%",
                    transform: stepperOpen ? "translateX(-50%)" : "translateX(-300%)",
                    transition: "1s",
                    position: "absolute",
                    maxWidth: "60vw",
                    bottom:
                        mediaType === EDraggableOptions.VIDEO || mediaType === EDraggableOptions.IMMERSIVE_VIDEO
                            ? "90px"
                            : "0px",
                }}
            >
                <ImageList
                    sx={{
                        overflowX: "auto",
                        marginRight: "-0.2px",
                        backgroundColor: alpha(theme.palette.background.paper, 0.6),
                    }}
                    rowHeight={150}
                >
                    {/*-----------------------------------------------------------------------------------------------*/}
                    {/* Close Stepper Button */}
                    <VTButton
                        sx={{
                            backgroundColor: alpha(theme.palette.background.paper, 0.6),
                            borderRadius: "15px 15px 0px 0px",
                            ":hover": {
                                backgroundColor: alpha(theme.palette.background.paper, 0.8),
                            },
                            position: "absolute",
                            bottom: "166px",
                            zIndex: 5,
                            height: "30px",
                            width: "30px",
                            left: "calc(50% - 30px)",
                        }}
                        onClick={() => {
                            setStepperOpen(false);
                        }}
                    >
                        <CloseRounded />
                    </VTButton>

                    {/*-----------------------------------------------------------------------------------------------*/}
                    {/* Previous Stepper item Button */}
                    <VTButton
                        sx={{
                            backgroundColor: alpha(theme.palette.background.paper, 0.6),
                            borderRadius: "15px 0px 0px 15px",
                            ":hover": {
                                backgroundColor: alpha(theme.palette.background.paper, 0.8),
                            },
                            position: "absolute",
                            left: -64,
                            zIndex: 5,
                            height: "150px",
                            width: "50px",
                        }}
                        disabled={stepperArray.findIndex((scene: IScene) => scene.id === currentSceneId) === 0}
                        onClick={() =>
                            handleItemClicked(
                                stepperArray[stepperArray.findIndex((scene: IScene) => scene.id === currentSceneId) - 1]
                                    .id,
                            )
                        }
                    >
                        <ArrowBackIosRounded />
                    </VTButton>

                    {/*-----------------------------------------------------------------------------------------------*/}
                    {/* Map Stepper Items */}
                    <ImageListItem
                        sx={{
                            display: "flex",
                            flexGrow: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        {stepperArray.map((scene: IScene) => {
                            return (
                                <VTStepperItem
                                    scene={scene}
                                    currentSceneId={currentSceneId}
                                    handleOnClick={() => handleItemClicked(scene.id)}
                                    disabled={disabled}
                                    imageOverride={imageOverride}
                                />
                            );
                        })}
                    </ImageListItem>

                    {/*-----------------------------------------------------------------------------------------------*/}
                    {/* Next Stepper Item Button */}
                    <VTButton
                        sx={{
                            backgroundColor: alpha(theme.palette.background.paper, 0.6),
                            borderRadius: "0px 15px 15px 0px",
                            ":hover": {
                                backgroundColor: alpha(theme.palette.background.paper, 0.8),
                            },
                            position: "absolute",
                            right: -64,
                            height: "150px",
                            width: "50px",
                        }}
                        disabled={
                            stepperArray.findIndex((scene: IScene) => scene.id === currentSceneId) ===
                            stepperArray.length - 1
                        }
                        onClick={() =>
                            handleItemClicked(
                                stepperArray[stepperArray.findIndex((scene: IScene) => scene.id === currentSceneId) + 1]
                                    .id,
                            )
                        }
                    >
                        <ArrowForwardIosRounded />
                    </VTButton>
                </ImageList>
            </Box>
        </>
    );
}
