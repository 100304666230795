import { Environment, GizmoHelper, GizmoViewport, Grid, OrbitControls, PivotControls } from "@react-three/drei";
import { useEffect, useState } from "react";
import CameraWireFrameHelper from "./CameraWireFrameHelper";
import { Canvas } from "@react-three/fiber";
import { useAppSelector } from "../../../store/hooks";

interface IProps {}

export default function ThreeDViewer({}: IProps) {
    console.log("here ****************************");
    const [editing, setEditing] = useState<string | undefined>(undefined);

    const { currentlyPreview } = useAppSelector((state) => state.currentReducer);

    return (
        <Canvas style={{ cursor: "grab" }}>
            <OrbitControls makeDefault enabled={editing === undefined} />
            <ambientLight />
            <GizmoHelper
                alignment="bottom-right" // widget alignment within scene
                margin={[80, 80]} // widget margins (X, Y)
            >
                <GizmoViewport axisColors={["red", "green", "blue"]} labelColor="black" />
            </GizmoHelper>
            <Environment preset="sunset" />

            <group position-y={-1} onClick={() => setEditing(undefined)}>
                <Grid args={[20, 20]} visible={!currentlyPreview} />
                <PivotControls
                    visible={editing === "camera" && !currentlyPreview}
                    annotations={false}
                    depthTest={true}
                    anchor={[-1, -1, -1]}
                    scale={0.75}
                >
                    <CameraWireFrameHelper
                        onClick={(e: any) => {
                            e.stopPropagation();
                            setEditing("camera");
                        }}
                    />
                </PivotControls>

                <mesh scale={5} position={[0, 2, -5]}>
                    <planeGeometry args={[2, 0.8]} />
                    <meshStandardMaterial color="gray" />
                </mesh>

                <mesh scale={5} rotation-y={-Math.PI} position={[0, 2, 5]}>
                    <planeGeometry args={[2, 0.8]} />
                    <meshStandardMaterial color="gray" />
                </mesh>

                <mesh scale={5} rotation-y={Math.PI / 2} position={[-5, 2, 0]}>
                    <planeGeometry args={[2, 0.8]} />
                    <meshStandardMaterial color="gray" />
                </mesh>

                <mesh scale={5} rotation-y={-Math.PI * 0.5} position={[5, 2, 0]}>
                    <planeGeometry args={[2, 0.8]} />
                    <meshStandardMaterial color="gray" />
                </mesh>

                <mesh scale={5} rotation-x={-Math.PI * 0.5} position-y={-0.002}>
                    <planeGeometry args={[2, 2]} />
                    <meshStandardMaterial color="white" />
                </mesh>
            </group>
        </Canvas>
    );
}
