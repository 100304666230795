import { createTheme } from "@mui/material";
import { defaultTheme } from "./default.theme";

export const lightTheme = createTheme({
    shape: {
        borderRadius: 15,
    },
    typography: {
        body2: {
            color: "#69646d",
        },
    },
    palette: {
        mode: "light",
        primary: {
            main: "#7315ce",
            light: "#8426df",
            dark: "#6204bd",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#69646d",
            light: "#7a757e",
            dark: "#58535c",
            contrastText: "#ffffff",
        },
        background: {
            default: "#ffffff",
            paper: "#efefef",
        },
        text: {
            primary: "#000",
            secondary: "#ffffff",
            disabled: "#69646d",
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "#7315ce",
                    color: "#ffffff",
                    ":hover": { backgroundColor: "#6204bd" },
                },
                colorSecondary: {
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    color: "#979797",
                    ":hover": { backgroundColor: "#a9a9a9", color: "#ffffff" },
                },
            },
        },

        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: "#69646d",
                    "&.Mui-error fieldset": {
                        borderColor: defaultTheme.palette.error.dark,
                    },
                },
            },
        },
    },
});
