//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
// Custom components imports (and hooks and helpers)
// Media imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILanguageModel } from "../../models/languege.model";
import { languageApi } from "../../services/language.service";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom

export interface ILangueState extends ILanguageModel {
    english: boolean;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
const languageInitialState: ILangueState = {
    english: true,
    otherName: "",
    otherImage: "",
};

const languageReducer = createSlice({
    name: "language",
    initialState: languageInitialState,
    reducers: {
        setEnglish: (state: ILangueState, { payload }: PayloadAction<boolean>) => {
            state.english = payload;
        },
    },
    extraReducers(builder) {
        builder.addMatcher(
            languageApi.endpoints.getLanguage.matchFulfilled,
            (state: ILangueState, { payload }: PayloadAction<ILanguageModel>) => {
                state.otherName = payload.otherName;
                state.otherImage = payload.otherImage;
            },
        );
    },
});

export const { setEnglish } = languageReducer.actions;

export default languageReducer.reducer;
