import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { textCutOffAddElipsis } from "../../helpers/UIHelpers";
import { useEffect } from "react";

interface IVTStepperItemProps {
    scene: IScene;
    handleOnClick: () => void;
    currentSceneId: string;
    disabled?: boolean;
    imageOverride?: any;
}

export default function VTStepperItem({
    scene,
    handleOnClick,
    currentSceneId,
    disabled = false,
    imageOverride,
}: IVTStepperItemProps) {
    const theme = useTheme();
    useEffect(() => {
        console.log("DBG:", scene.src.split("/").at(-1));
    }, [scene]);

    return (
        <Box
            component={"div"}
            sx={{
                width: "150px",
                height: "100px",
                marginInline: "15px",
                cursor: "pointer",
            }}
            onClick={!disabled ? handleOnClick : () => {}}
        >
            {imageOverride ? (
                imageOverride
            ) : (
                <img
                    src={
                        scene?.mediaType === EDraggableOptions.IMMERSIVE_VIDEO ||
                        scene?.mediaType === EDraggableOptions.VIDEO
                            ? `${scene.src.split(".mp4")[0]}_preview.jpg`
                            : `${scene.src}/${scene.src.split("/").at(-1)}_preview.jpg`
                    }
                    style={{
                        borderRadius: "15px",
                        objectFit: "cover",
                        boxShadow: currentSceneId === scene.id ? "0 0 25px #fff" : "none",
                        opacity: disabled ? "0.5" : "1",
                    }}
                    alt="preview"
                    width="100%"
                    height="80px"
                />
            )}
            <Tooltip title={scene.name} disableHoverListener={scene.name.length <= 16}>
                <Typography
                    align={"center"}
                    color={disabled ? `${theme.palette.text.disabled}` : `${theme.palette.text.primary}`}
                >
                    {scene.name.length <= 16 ? scene.name : textCutOffAddElipsis(scene.name, 16)}
                </Typography>
            </Tooltip>
        </Box>
    );
}
