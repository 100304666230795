import { LoginContainer } from "../containers/LoginContainer";
import { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setCurrentPage } from "../../store/slices/current.slice";
import ReactGA from "react-ga4";
import { useSearchParams } from "react-router-dom";
import { DemoLoginWithParamsContainer } from "../containers/DemoLoginWithParamsContainer";
import { DemoLoginNoParamsContainer } from "../containers/DemoLoginNoParamsContainer";

// Only display layouts in pages
export default function LoginPage() {
    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();

    useEffect(() => {
        dispatch(setCurrentPage("login"));
        ReactGA.send({ hitType: "pageview", page: "/Login", title: "Login" });
    }, []);

    if (process.env.REACT_APP_DEMO === "true" && searchParams && searchParams.get("email")) {
        return <DemoLoginWithParamsContainer />;
    } else if (process.env.REACT_APP_DEMO === "true") {
        return <DemoLoginNoParamsContainer />;
    } else {
        return <LoginContainer />;
    }
}
