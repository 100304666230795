function getSecondsElapsedIn60Seconds(millisecondsElapsed: number): number {
    return Math.floor((millisecondsElapsed / 1000) % 60);
}

function getTimeElapsedInMinutes(millisecondsElapsed: number): number {
    return Math.floor(millisecondsElapsed / 1000 / 60);
}

function getCurrentMillaSecondElapsedAsFraction(millisecondsElapsed: number): number {
    return (millisecondsElapsed % 1000) / 1000;
}

export function getTimeElapsedInStopWatchFormatString(millisecondsElapsed: number): string {
    let minsNumberAsString = String(getTimeElapsedInMinutes(millisecondsElapsed));
    let minsAs2Digits = minsNumberAsString.length > 1 ? minsNumberAsString : "0" + minsNumberAsString;

    let secondsNumberAsString = String(getSecondsElapsedIn60Seconds(millisecondsElapsed));
    let secondsAs2Digits = secondsNumberAsString.length > 1 ? secondsNumberAsString : "0" + secondsNumberAsString;

    let millaSecondAs2DigitNumberAsString =
        "0" + String(Math.floor(getCurrentMillaSecondElapsedAsFraction(millisecondsElapsed) * 10));

    return `${minsAs2Digits}:${secondsAs2Digits}:${millaSecondAs2DigitNumberAsString}`;
}
