//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
// Custom components imports (and hooks and helpers)
import { useAppSelector } from "../../store/hooks";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import {
    CircularProgress,
    Grid,
    Skeleton,
    Slider,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import { ArrowForwardRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { Params, useNavigate, useParams } from "react-router";
import * as animationData from "../../assets/icons/vh_logo.json";
import { ArrowBackIosRounded, ArrowForwardIosRounded } from "@mui/icons-material";
import { AddRounded, CancelRounded, SaveRounded, DeleteRounded } from "@mui/icons-material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { EOrganisation } from "../../models/organisations.model";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";

import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { IScenario, IScene, IStep } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { useDeleteSceneMutation, useGetScenesQuery, useUpdateSceneMutation } from "../../services/scene.service";

import { DropPoint } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCreatorDropContainer";
import { v4 as uuid } from "uuid";
import { getSceneDataFromScenesArrBySceneId } from "../../utils/getSceneDataFromScenesArrBySceneId";
import { blankScene } from "../../helpers/scenarioDataGenerator";

import NoPreview from "../../assets/images/NoScenarioImage.svg";
import NoPreviewFile from "../../assets/images/NoFilesImage.svg";
import PassthroughPreview from "../../assets/images/PassthroughPreview.svg";
import Model3DPreview from "../../assets/images/Model3dPreview.svg";
import sleep from "../../utils/sleep";
import VTStepper from "../components/VTStepper";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    timelineMode: "Scenes" | "Timeline" | "Stepper";
    setTimelineMode: (timelineMode: "Scenes" | "Timeline" | "Stepper") => void;
    mediaType: any;
    videoNextQuestion: IHotspot | undefined;
    getNextQuestion: (time: number) => void;
    hotspots: IHotspot[] | undefined;
    videoCurrentTime: number;
    setSortedQuestions: (sortedQuestions: IHotspot[]) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Panel for the creator timeline
export default function VTCreatorTimelineContainer({
    timelineMode,
    setTimelineMode,
    mediaType,
    videoNextQuestion,
    getNextQuestion,
    hotspots = [],
    videoCurrentTime,
    setSortedQuestions,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const navigate = useNavigate();
    const [stepperArray, setStepperArray] = useState<IScene[]>([]);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { data: scenes } = useGetScenesQuery(scenarioId ? scenarioId : "");

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (scenes) {
            let newStepperArray: IScene[] = [];

            scenes.map((scene: IScene) => scene.stepper !== undefined && newStepperArray.push(scene));

            if (newStepperArray.length === 0 && timelineMode === "Stepper") {
                setTimelineMode("Scenes");
            }

            setStepperArray(newStepperArray);
        }
    }, [scenes]);

    useEffect(() => {
        if (videoNextQuestion && hotspots && hotspots.length) {
            let tempSortedArray = hotspots.filter(
                (hotspotFilterElement: IHotspot) => hotspotFilterElement.type === EDraggableOptions.QUESTION,
            );
            tempSortedArray.sort((a: IHotspot, b: IHotspot) =>
                a.contents.preClickContent.question!.time > b.contents.preClickContent.question!.time ? 1 : -1,
            );
            setSortedQuestions(tempSortedArray);
        } else if (!videoNextQuestion && hotspots && hotspots.length && videoCurrentTime === 0) {
            getNextQuestion(0);
        }
    }, [videoNextQuestion, hotspots, scenes]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Function to get src and size images based on size

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Render the timeline with a panel
    return (
        <>
            {stepperArray.length > 1 && (
                <VTStepper
                    stepperArray={stepperArray}
                    currentSceneId={sceneId!}
                    mediaType={mediaType}
                    handleItemClicked={(sceneId: string) => navigate(`/creator/${scenarioId}/${sceneId}`)}
                />
            )}
        </>
    );
}
