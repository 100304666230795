//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
// Custom components imports (and hooks and helpers)
// Media imports
import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";

import { languageApi } from "../services/language.service";
import { MediaApi } from "../services/media.service";
import { ScenarioApi } from "../services/scenario.service";
import { UserApi } from "../services/users.service";
import { HotspotApi } from "../services/hotspot.service";
import { SceneApi } from "../services/scene.service";
import { ScenarioAccessApi } from "../services/scenarioAccess.service";

import currentReducer from "./slices/current.slice";
import languageReducer from "./slices/language.slice";
import themeReducer from "./slices/theme.slice";
import userReducer from "./slices/user.slice";
import mediaUploadReducer from "./slices/mediaUpload.slice";
import trackingReducer from "./slices/tracking.slice";
import { TrackingApi } from "../services/tracking.service";

export const store = configureStore({
    reducer: {
        currentReducer,
        themeReducer,
        languageReducer,
        userReducer,
        mediaUploadReducer,
        trackingReducer,
        // Add the generated api educers as a specific top-level slice
        [HotspotApi.reducerPath]: HotspotApi.reducer,
        [SceneApi.reducerPath]: SceneApi.reducer,
        [ScenarioApi.reducerPath]: ScenarioApi.reducer,
        [ScenarioAccessApi.reducerPath]: ScenarioAccessApi.reducer,
        [UserApi.reducerPath]: UserApi.reducer,
        [MediaApi.reducerPath]: MediaApi.reducer,
        [languageApi.reducerPath]: languageApi.reducer,
        [TrackingApi.reducerPath]: TrackingApi.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(
            HotspotApi.middleware,
            SceneApi.middleware,
            ScenarioApi.middleware,
            ScenarioAccessApi.middleware,
            UserApi.middleware,
            MediaApi.middleware,
            languageApi.middleware,
            TrackingApi.middleware,
        ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
