import { EOrganisation } from "../models/organisations.model";

export default function WordingHelper(organisation: string | undefined, plural?: boolean): string {
    // SPAVE AT THE END IS IMPORTANT
    switch (organisation) {
        case EOrganisation.ECO_GOAL:
            return plural ? "Blogs" : "Blog ";
        case EOrganisation.NOVA:
            return plural ? "Inventories" : "Inventory ";
        default:
            return plural ? "Scenarios" : "Scenario ";
    }
}
